import React from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import { css } from '@emotion/react'
import { mobile, tablet } from '~styles/global'
import Video from '~components/Video'
import Section from '~components/Section'

const VideoSection = ({ className, content }) => {
	console.log(content)
	return (
		<Wrap className={className} color={content?.backgroundColor}>
			<StyledVideo src={content?.video?.video?.asset?.url} showControls={content?.showControls} />
		</Wrap>
	)
}

const Wrap = styled(Section)`
	background-color: ${(props) => (props.color ? `var(--${props.color === 'pink' ? '--light-pink' : props.color})` : 'var(--black)')};
	padding: 150px 0;
	${mobile} {
		padding: 80px 0;
	}
`
const StyledVideo = styled(Video)`
	grid-column: span 12;
	width: 100%;
	max-width: 1000px;
	margin: 0 auto;
`

VideoSection.propTypes = {
	className: PropTypes.string,
	content: PropTypes.object,
}

export default VideoSection
