import React from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import { css } from '@emotion/react'
import { mobile, tablet, InsetSection } from '~styles/global'
import RichText from '~components/RichText'

const TextSection = ({ className, content }) => {
	return (
		<Wrap className={className}>
			<Col>
				<RichText content={content}/>
			</Col>
		</Wrap>
	)
}

const Wrap = styled(InsetSection)`
	>div{
		margin: 66px 0;
	}
	${mobile}{
		>div {
			margin: 46px 0;
		}
	}
`
const Col = styled.div`
	grid-column: 1/6;
	${tablet}{
		grid-column: span 12;
	}
`

TextSection.propTypes = {
	className: PropTypes.string,
	content: PropTypes.object,
}

export default TextSection