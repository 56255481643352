import React, { useCallback, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import { css } from '@emotion/react'
import { mobile, tablet } from '~styles/global'
import Player from '@vimeo/player'
import useMouseMoving from '~utils/useMouseMoving'

const Vimeo = ({ className, videoId, placeholder, aspectRatio }) => {

	const [player, setPlayer] = useState()
	const [play, setPlay] = useState(false)
	const [hover, setHover] = useState()
	const [mouseMoving, mouseStopped] = useMouseMoving()

	// Vimeo variables

	const videoContainer = useCallback(node => {
		let options = {
			id: videoId,
			loop: true,
			byline: false,
			title: false,
			portrait: false,
			controls: false,
			muted: false
		}
		if(node) setPlayer(new Player(node, options)) 
	}, [])

	const playVideo = () => {
		setPlay(true)
		player?.play()
	}

	const pauseVideo = () => {
		setPlay(false)
		player?.pause()
	}

	return (
		<Wrap 
			className={className}
			onMouseEnter={() => setHover(true)}
			onMouseLeave={() => setHover(false)}
		>
			<Inner>
				<Placeholder aspectRatio={1.7778}/>
				<PlayButton 
					className='h1' 
					onClick={playVideo}
					play={play}
				>
					Play
				</PlayButton>
				<PauseButton 
					className='h1' 
					onClick={pauseVideo}
					play={play}
					show={hover && mouseMoving}
				>
					Pause
				</PauseButton>
				<div ref={videoContainer} 
					css={css`
						position: relative; 
						height: 0; 
						overflow: hidden; 
						max-width: 100%;
						width: 100%;
						transition: opacity 1s;
						padding-bottom: ${1 / aspectRatio * 100 + '%'};
						iframe{
							position: absolute;
							top: 0;
							left: 0;
							width: 100%;
							height: 100%;
						}
					}
			`}/>
			</Inner>
		</Wrap>
	)
}

const Wrap = styled.div`
	position: relative;
	background-color: var(--black);
`
const Inner = styled.div`
	overflow-x: hidden;
	position: relative;
`
const Placeholder = styled.div`
	
`
const PlayButton = styled.button`
	position: absolute;
	top: 0;
	right: 0;	
	bottom: 0;
	left: 0;
	width: 100%;
	text-align: center;
	color: var(--white);
	z-index: 1;
	pointer-events: ${props => props.play ? 'none' : 'all'};
	opacity: ${props => props.play ? '0' : '1'};
	transition: opacity 0.2s;
`
const PauseButton = styled(PlayButton)`
	pointer-events: ${props => props.play && props.show ? 'all' : 'none'};
	opacity: ${props => props.play && props.show ? '1' : '0'};
`

Vimeo.propTypes = {
	className: PropTypes.string
}

export default Vimeo