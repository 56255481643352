import { useEffect, useState } from 'react'

const useMouseMoving = () => {
	const [mouseMoving, setMouseMoving] = useState(false)
	const [mouseStopped, setMouseStopped] = useState(true)

	useEffect(() => {
		if(!mouseMoving){
			setMouseStopped(true)
		} else {
			setMouseStopped(false)
		}
	}, [mouseMoving])

	let mouseTimeout

	const handleMouseMove = () => {
		setMouseMoving(true)
		clearTimeout(mouseTimeout)
		mouseTimeout = setTimeout(() => {
			setMouseMoving(false)
		}, 600)
	}

	useEffect(() => {
		window.addEventListener('mousemove', handleMouseMove)
		return () => 		
			window.removeEventListener('mousemove', handleMouseMove)
	}, [])

	return [mouseMoving, mouseStopped]
}

export default useMouseMoving