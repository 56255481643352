import React from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import { css } from '@emotion/react'
import { mobile, tablet } from '~styles/global'

const Button = ({ className, children, onClick }) => {
	return (
		<Wrap className={`sans-sm ${className}`} onClick={onClick}>
			{ children }
		</Wrap>
	)
}

const Wrap = styled.button`
	
`

Button.propTypes = {
	className: PropTypes.string,
	children: PropTypes.oneOfType([
		PropTypes.object,
		PropTypes.array,
	]),
	onClick: PropTypes.func,
}

export default Button