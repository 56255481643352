import React from 'react'
import PropTypes from 'prop-types'

import MarqueeSlider from '~components/MarqueeSlider'
import TextSection from '~components/TextSection'
import VideoSection from '~components/VideoSection'
import VimeoSection from '~components/VimeoSection'
import ImageSection from '~components/ImageSection'
import VimeoInline from '~components/VimeoInline'

const RenderModules = ({ className, modules }) => {
	if (!modules) return null
	return (
		<div className={className}>
			{modules.map((module) => (
				<div key={module._key}>
					<RenderModule module={module} />
				</div>
			))}
		</div>
	)
}

const RenderModule = ({ module }) => {
	switch (module._type) {
		case 'mediaSlider':
			return <MarqueeSlider slides={module?.slides} />
		case 'videoSection':
			return <VideoSection content={module} />
		case 'textSection':
			return <TextSection content={module?.text} />
		case 'vimeoInline':
			return <VimeoInline videoId={module?.vimeoUrl?.oEmbedData?.video_id} showControls={module?.showControls} aspectRatio={module?.vimeoUrl?.oEmbedData?.width / module?.vimeoUrl?.oEmbedData?.height} />
		case 'video':
			return (
				<VimeoSection
					videoId={module?.vimeoUrl?.oEmbedData?.video_id}
					aspectRatio={module?.vimeoUrl?.oEmbedData?.width / module?.vimeoUrl?.oEmbedData?.height}
				// placeholder={module?.placeholder}
				/>
			)
		case 'imageSection':
			return <ImageSection image={module?.image} color={module?.backgroundColor} size={module?.size} />
		default:
			return null
	}
}

RenderModules.propTypes = {
	className: PropTypes.string,
	modules: PropTypes.array,
}
RenderModule.propTypes = {
	module: PropTypes.object,
}

export default RenderModules
